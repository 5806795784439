<template>
  <div class="navigation">
		<slot></slot>
	</div>
</template>

<script>
export default {
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.navigation{
	width: var(--navigation-width);
	padding: var(--navigation-padding);
}
</style>

<!-- Used in : DS -->
